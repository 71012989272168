import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Img from 'gatsby-image';

const Intro = ({headerImage, contents}) => {
    return (
        <div className="section">
            <div className="container">
                <div className="row section-row">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center">
                        <h1>{contents.titleLeft}</h1>
                        <p>
                            <ReactMarkdown
                                source={contents.contentsLeft}
                                escapeHtml={false}
                            />
                        </p>
                        <a
                            href="https://thorgate.eu/contact/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="orange-button"
                        >
                            Contact us
                        </a>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <Img fluid={headerImage.childImageSharp.fluid} />
                    </div>
                </div>
                <div className="row section-row d-flex justify-content-end">
                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <h2>
                            <ReactMarkdown
                                source={contents.contentsRight}
                                escapeHtml={false}
                            />
                        </h2>
                        <div className="d-flex justify-content-end mt-5">
                            <a
                                href="https://thorgate.eu/contact/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="orange-button-wide"
                            >
                                Contact us now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    headerImage: PropTypes.object.isRequired,
    contents: PropTypes.object.isRequired,
};

export default Intro;
