import React from 'react';

const HaveQuestions = () => (
    <div className="section">
        <div className="container">
            <div className="row section-row d-flex align-items-end">
                <h1 className="col-lg-6 col-md-6">Have any questions?</h1>
                <p className="col-lg-6 col-md-6">
                    Feel free to reach out to us via{' '}
                    <a href="mailto:hi@thorgate.eu" className="emailLink">
                        hi@thorgate.eu
                    </a>
                </p>
            </div>
        </div>
    </div>
);

export default HaveQuestions;
