import React from 'react';
import {SEO} from 'components';
import {useStaticQuery, graphql} from 'gatsby';

import Navbar from 'components/Navbar';
import Intro from 'components/Home/Intro';
import TechStack from 'components/Home/TechStack';
import WorkTogether from 'components/Home/WorkTogether';
import Quotes from 'components/Home/Quotes';
import HaveQuestions from 'components/Home/HaveQuestions';
import CaseStudies from 'components/Home/CaseStudies';

import 'components/Home/Home.scss';

const Home = () => {
    const data = useStaticQuery(graphql`
        query PageContentQuery {
            headerImage: file(relativePath: {eq: "header-image.png"}) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            caseStudyEcoop: file(relativePath: {eq: "ecoop.png"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            caseStudyNovastar: file(relativePath: {eq: "novastar.png"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            caseStudyKrahPipes: file(relativePath: {eq: "krah.png"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allFile(filter: {name: {eq: "home"}}) {
                edges {
                    node {
                        id
                        childMarkdownRemark {
                            frontmatter {
                                intro {
                                    titleLeft
                                    contentsLeft
                                    contentsRight
                                }
                                whyThisTechStack {
                                    heading
                                    contentHeading
                                    content
                                }
                                workTogether {
                                    heading
                                    content
                                }
                                quotes {
                                    author
                                    quote
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const pageContentNode = data.allFile.edges[1] || data.allFile.edges[0];
    const pageContent = pageContentNode.node.childMarkdownRemark.frontmatter;

    const quotesList = pageContent.quotes;

    const caseStudies = {
        ecoop: data.caseStudyEcoop,
        novastar: data.caseStudyNovastar,
        krah: data.caseStudyKrahPipes,
    };

    return (
        <>
            <SEO
                title="Python Django React | Thorgate"
                description="Join us in changing the world with technology"
            />
            <Navbar />
            <Intro
                headerImage={data.headerImage}
                contents={pageContent.intro}
            />
            <TechStack sectionContent={pageContent.whyThisTechStack} />
            <WorkTogether sectionContent={pageContent.workTogether} />
            <CaseStudies caseStudies={caseStudies} />
            <Quotes quotesList={quotesList} />
            <HaveQuestions />
        </>
    );
};

export default Home;
