import React, {useState} from 'react';

import './Navbar.scss';
import DjangoPythonReactLogo from 'images/icons/python-django-react.svg';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className={`navbar navbar--${isOpen ? 'open' : 'closed'}`}>
            <div className="container navbar__container">
                <div className="navbar__left">
                    <DjangoPythonReactLogo
                        className="navbar__logo"
                        height="100"
                        width="200"
                    />
                    <span>Python Django React</span>
                </div>
                <button
                    className={`navbar__toggle hamburger hamburger--slider ${
                        isOpen ? 'is-active' : ''
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                    aria-label="Menu"
                    aria-controls="navigation"
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <ul id="navigation" className="navbar__items">
                    <li className="navbar__item">
                        <a
                            className="btn navbar__button"
                            href="https://thorgate.eu/contact/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Contact us
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
