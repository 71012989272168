import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const CaseStudies = ({caseStudies}) => {
    return (
        <div className="section">
            <div className="container">
                <div className="row section-row">
                    <div className="d-flex flex-column">
                        <h2 className="text-center">
                            Some of our digitally transforming projects
                        </h2>
                        <div className="container d-flex flex-wrap mt-5">
                            <div className="d-flex flex-column col-lg-4 col-md-4 col-sm-10">
                                <Img
                                    fluid={
                                        caseStudies.ecoop.childImageSharp.fluid
                                    }
                                />
                                <h3 className="text-center">eCoop</h3>
                                <a
                                    href="https://thorgate.eu/ecoop/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center"
                                >
                                    View case study
                                </a>
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 col-sm-10">
                                <Img
                                    fluid={
                                        caseStudies.novastar.childImageSharp
                                            .fluid
                                    }
                                />
                                <h3 className="text-center">Novastar</h3>
                                <a
                                    href="https://thorgate.eu/novastar/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center"
                                >
                                    View case study
                                </a>
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4  col-sm-10">
                                <Img
                                    fluid={
                                        caseStudies.krah.childImageSharp.fluid
                                    }
                                />
                                <h3 className="text-center">Krah Pipes</h3>
                                <a
                                    href="https://thorgate.eu/krah-pipes/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center"
                                >
                                    View case study
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CaseStudies.propTypes = {
    caseStudies: PropTypes.shape({
        ecoop: PropTypes.object.isRequired,
        novastar: PropTypes.object.isRequired,
        krah: PropTypes.object.isRequired,
    }).isRequired,
};

export default CaseStudies;
