import React, {useState} from 'react';
import PropTypes from 'prop-types';

import ArrowLeft from 'images/icons/arrow-2.svg';
import ArrowRight from 'images/icons/arrow-1.svg';
import QuoteClose from 'images/icons/quote.svg';
import QuoteOpen from 'images/icons/quote-2.svg';

const Quotes = ({quotesList}) => {
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

    const previousQuote = () => {
        const index =
            currentQuoteIndex === 0 ? quotesList.length : currentQuoteIndex;
        setCurrentQuoteIndex((index - 1) % quotesList.length);
    };

    const nextQuote = () => {
        setCurrentQuoteIndex((currentQuoteIndex + 1) % quotesList.length);
    };

    return (
        <div className="section-image-background d-flex align-items-center">
            <ArrowLeft
                onClick={previousQuote}
                className="btn btn-link slider-arrow col-lg-1 col-sm-2"
            />
            <div className="col-lg-10 col-sm-8 pl-0 pr-0">
                <div className="container pl-0 pr-0">
                    <div className="row section-row">
                        <div className="quote-text-container">
                            <div className="col-1 d-flex align-items-start">
                                <QuoteOpen />
                            </div>
                            <div className="col-10 text-center">
                                <div className="quote-text">
                                    <p>{quotesList[currentQuoteIndex].quote}</p>
                                </div>
                                <div className="quote-author">
                                    <p>
                                        - {quotesList[currentQuoteIndex].author}
                                    </p>
                                </div>
                            </div>
                            <div className="col-1 d-flex align-items-end">
                                <QuoteClose />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ArrowRight
                onClick={nextQuote}
                className="btn btn-link slider-arrow col-lg-1 col-sm-2"
            />
        </div>
    );
};

Quotes.propTypes = {
    quotesList: PropTypes.arrayOf(
        PropTypes.shape({
            quote: PropTypes.string.isRequired,
            author: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default Quotes;
