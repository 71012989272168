import React from 'react';
import PropTypes from 'prop-types';

import PythonIcon from 'images/icons/python_orange.svg';
import DjangoIcon from 'images/icons/django_orange.svg';
import ReactIcon from 'images/icons/react_orange.svg';

const TechStack = ({sectionContent}) => (
    <div className="section">
        <div className="container">
            <div className="row section-row d-flex justify-content-center">
                <h1>{sectionContent.heading}</h1>
            </div>
            <div className="row section-row">
                <div className="col-lg-4 col-md-4 d-flex flex-column text-center">
                    <div>
                        <PythonIcon className="tech-logo" />
                    </div>
                    <h3>
                        <strong>Python</strong>
                    </h3>
                    <div className="column-text">
                        <p>
                            A high-level, general purpose programming language.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-column text-center">
                    <div>
                        <DjangoIcon className="tech-logo" />
                    </div>
                    <h3>Django</h3>
                    <div className="column-text">
                        <p>
                            A web framework for backend Python-based web
                            applications.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-column text-center">
                    <div>
                        <ReactIcon className="tech-logo" />
                    </div>
                    <h3>React.js</h3>
                    <div className="column-text">
                        <p>
                            A javascript library used for building user
                            interfaces.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section-row">
                <h2 className="font-weight-bold text-center">
                    {sectionContent.contentHeading}
                </h2>
                <p className="col-12 mt-5">{sectionContent.content}</p>
            </div>
        </div>
    </div>
);

TechStack.propTypes = {
    sectionContent: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        contentHeading: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    }).isRequired,
};

export default TechStack;
