import React from 'react';
import PropTypes from 'prop-types';

const WorkTogether = ({sectionContent}) => {
    return (
        <div className="section-gray">
            <div className="container ">
                <div className="section-row d-flex align-items-center flex-wrap">
                    <h1 className="col-lg-4">{sectionContent.heading}</h1>
                    <p className="col-lg-6">{sectionContent.content}</p>
                    <div className="col-lg-2 pl-4">
                        <a
                            href="https://thorgate.eu/contact/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="orange-button"
                        >
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

WorkTogether.propTypes = {
    sectionContent: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    }).isRequired,
};

export default WorkTogether;
